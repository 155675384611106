import type { RouteRecordRaw, Router } from 'vue-router';
import auth from '@/app/middleware/auth';
import isTeacher from '@/app/middleware/isTeacher';

const moduleRoute: RouteRecordRaw = {
  path: '/stats',
  name: 'Stats',
  component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
  meta: {
    middleware: [auth, isTeacher],
  },
  children: [
    {
      path: '',
      name: 'Stats.Main',
      meta: {
        title: 'Stats',
      },
      component: () => import('@/modules/stats/pages/Main.vue'),
    },
    {
      path: '/best-students',
      name: 'Stats.BestStudents',
      meta: {
        title: 'BestStudents',
      },
      component: () => import('@/modules/stats/pages/BestStudents.vue'),
    },

  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
